<template lang="pug">
div
  TableV2(
    :isViewAdd="false"
    :headers="headers"
    :items="items"
    :pageCount="0"
    :currentPage="0"
    :querySearch="getDocuments"
    :isLoading="isLoading"
    isHiddenTableSearch
    isHiddenTableCount
    isHiddenPageCount
    componentInfo="BackOfficeDocumentsPriceOnlineInfo"
    showExpand
  )
    BackOfficeDocumentsPriceOnlineAdd(slot="AddBlock")
</template>

<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'typeDocName', text: this.$t('typeDoc'), sortable: false },
        { value: 'date_start', text: this.$t('dateEffective'), sortable: false },
        { value: 'full_price', text: this.$t('coming'), sortable: false },
        { value: 'to_sqc', text: this.$t('toSQC'), cellClass: 'border-l', class: 'border-l', sortable: false },
        { value: 'to_qd', text: this.$t('toQD'), sortable: false },
        { value: 'to_td', text: this.$t('toTD'), sortable: false },
        { value: 'to_sc', text: this.$t('toSC'), sortable: false },
        { value: 'to_crewing', text: this.$t('toCrewingManager'), sortable: false },
        { value: 'to_medical', text: this.$t('toMedical'), sortable: false },
        { value: 'to_cec', text: this.$t('toCEC'), sortable: false },
        { value: 'to_mrc', text: this.$t('toMRC'), sortable: false },
        { value: 'to_portal', text: this.$t('toPortal'), cellClass: 'border-r', class: 'border-r', sortable: false },
        { value: 'sum_to_distribution', text: this.$t('all'), sortable: false },
        { value: 'profit', text: this.$t('profit'), sortable: false },
        // { value: 'event', text: this.$t('actions'), //   class: 'mw-0', sortable: false },
        { text: '', value: 'data-table-expand' }
      ],
      isLoading: false
    }
  },
  components: {
    BackOfficeDocumentsPriceOnlineAdd: () => import('../Form.vue')
  },
  computed: {
    ...mapState({
      items: state => state.backoffice.documentsPriceOnline
    })
  },
  methods: {
    ...mapActions(['getDocumentsPriceOnlineActualValue']),
    async getDocuments (filter) {
      this.isLoading = true
      await this.getDocumentsPriceOnlineActualValue(filter)
      this.isLoading = false
    }
  }
}
</script>
